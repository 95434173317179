@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    /*--foreground: 221 14% 42%; */
    --foreground: 0 0% 27%;

    --muted: 0 0% 98%;
    --muted-foreground: 0 0% 49%;

    --popover: var(--background);
    --popover-foreground: var(--secondary-foreground);

    --card: var(--background);
    --card-shadow: 5px 5px 15px 0 rgba(85, 85, 85, 0.05);
    --card-shadow-sm: 2px 2px 4px 0 rgba(85, 85, 85, 0.05);
    --card-foreground: var(--popover-foreground);

    --border: 0 0% 93%;

    --input: var(--border);
    --input-shadow: 0px 2px 4px rgba(85, 85, 85, 0.05);

    --link: var(--primary);

    --primary: 227 66% 50%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: var(--foreground);

    --accent: 219 88% 97%;
    --accent-foreground: var(--secondary-foreground);

    --destructive: 0 100% 50%;
    --destructive-foreground: var(--primary-foreground);

    --ring: var(--primary);

    --radius: 0.5rem;

    --window-keeper-height: max-h-[calc(100vh-70px)];
    --show-window-keeper: hidden;
  }

  .dark {
    --background: 222 46% 11%;
    --foreground: 213 31% 91%;

    --muted: 222 46% 14%;
    --muted-foreground: 215.4 16.3% 56.9%;

    --popover: var(--background);
    --popover-foreground: 215 20.2% 65.1%;

    --card: var(--background);
    --card-foreground: var(--foreground);

    --border: 234 25% 23%;
    --input: var(--border);
    --link: 202 89% 61%;

    --primary: 207 74% 79%;

    --primary-foreground: 222 46% 11%;

    --secondary: 222.2 47.4% 21.2%;
    --secondary-foreground: var(--primary-foreground);

    --accent: 216 34% 17%;

    --accent-foreground: var(--primary-foreground);

    --destructive: 0 63% 31%;
    --destructive-foreground: var(--primary-foreground);

    --ring: var(--primary);

    --radius: 0.5rem;
  }
  .custom {
    --background: 222 46% 11%;
    --foreground: 213 31% 91%;

    --muted: 222 46% 14%;
    --muted-foreground: 215.4 16.3% 56.9%;

    --popover: var(--background);
    --popover-foreground: 215 20.2% 65.1%;

    --card: var(--background);
    --card-foreground: var(--foreground);

    --border: 234 25% 23%;
    --input: var(--border);
    --link: 202 89% 61%;

    --primary: 238 70% 57%;

    --primary-foreground: 210 40% 98%;

    --secondary: 222.2 47.4% 21.2%;
    --secondary-foreground: var(--primary-foreground);

    --accent: 216 34% 17%;

    --accent-foreground: var(--primary-foreground);

    --destructive: 0 63% 31%;
    --destructive-foreground: var(--primary-foreground);

    --ring: var(--primary);

    --radius: 0.5rem;
  }
}

@layer base {
  * {
    @apply border-border;
  }

  body {
    @apply bg-background text-foreground;
    font-feature-settings:
      'rlig' 1,
      'calt' 1;

    /* -webkit-font-smoothing: antialiased; */
    text-rendering: optimizeLegibility;

    min-height: 100vh;

  }

  section[aria-label="Notifications alt+T"] {
    @apply pointer-events-auto
  }

  * > * {
    box-sizing: border-box;
  }

  h1,
  .h1 {
    @apply text-2xl font-semibold leading-snug;
  }

  h2,
  .h2 {
    @apply text-xl font-semibold;
  }

  h3,
  .h3 {
    @apply text-sm font-semibold tracking-tight;
  }

  h4,
  .h4 {
    @apply text-base font-semibold tracking-tight;
  }

  p,
  .p {
    @apply text-sm font-normal leading-snug;
  }

  /* Removes the default X mark when input has role='search'  */
  ::-webkit-search-cancel-button {
    @apply appearance-none 
  }
}

.spreadsheet__selected__cell {
  border-color: #5165fb !important;
  border-width: 1px !important;
}

@layer utilities {
    @layer responsive {
        .masonry {
            column-gap: 1.5em;
            column-count: 1;
        }
        .masonry-sm {
            column-gap: 1.5em;
            column-count: 2;
        }
        .masonry-md {
            column-gap: 1.5em;
            column-count: 3;
        }
        .break-inside {
            break-inside: avoid;
        }

    }
}
